<template>
  <v-app>
    <div>
        <div id="main" class="d-flex align-center justify-end ">
          <v-container class="mt-0 pt-0">
            <h3 class="text-right font-weight-black "> Subscribe to our products </h3>
            <h3 class="text-right font-weight-lighter  ">
              Connect, build, and coordinate using one account
            </h3>
            <div class="w-100 text-right">
              <v-btn id="btn" @click="createAccount" large min-width="250" class="white--text mt-10 " outlined rounded >Get Started</v-btn >
            </div>
          </v-container>
        </div>
    </div>

    <!-- Products -->
    <div>
      <v-container class="mt-12">
        <h2 class="text-center">Featured Products</h2>
        <Products class="mt-12" />
      </v-container>
    </div>
  </v-app>
</template>
<script>
import Products from "../components/Product/productspartial";

export default {
  components: {
    Products,
  },
  data: () => ({
    model: "",
  }),
  created() {
    if (this.$user.SuccessLogin()) {
      this.$router.push({ name: "Dashboard" });
    }
  },
  methods: {
    createAccount() {
      window.location.href = this.$config.login
    },
  },
};
</script>
<style scoped>
#main {
  background-image: linear-gradient(
      to left,
      rgba(2, 0, 36, 0.8),
      rgba(0, 146, 155, 0.3)
    ),
    url("../assets/Svg/Products.svg");
  color: white;
  background-position: center;
  background-size: cover;
   height: 420px;
   padding: 20px;
}
.btn {
  text-transform: none;
}
#targetAudeince path {
  fill: transparent;
}
</style>

